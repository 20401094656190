export default {
  errors: {
    people: {
      savePerson: {
        generic: 'We konden uw gegevens niet opslaan.',
        noDonationAmount: 'U heeft een ongeldig donatiebedrag ingevoerd.',
      },
    },
    orders: {
      createFailure: 'We konden uw bestelling niet aanmaken.',
      unknownUser:
        'U probeert een bestelling te maken voor een onbekende gebruiker. Log opnieuw in en probeer opnieuw.',
    },
  },
  account: {
    add_store: 'Nieuw verkooppunt',
    complete_registration:
      'Vul uw persoonsgegevens in om de registratie te voltooien.',
    log_out: 'Uitloggen',
    stores: 'Verkooppunten',
    welcome: 'Welkom',
  },
  address: {
    country: 'Land',
    postal_code: 'Postcode',
    house_number: 'Huisnummer',
    house_number_suffix: 'Toevoeging',
    street: 'Straat',
    locality: 'Woonplaats',
  },
  donation_payment: {
    thanks: 'Dank voor het doorgeven van uw donatie.',
    do_first_payment:
      'U kunt uw donatie nu afronden. Wilt u ieder jaar de nieuwe wimpel als eerste automatisch thuis ontvangen? Kies dan voor de jaarlijkse donatie met incasso. Wij zullen voortaan incasseren vanaf het rekeningnummer waarmee u nu gaat betalen.',
    optionally_once:
      'Indien u Marrekrite niet wilt machtigen tot automatische incasso kies dan voor: Doneer eenmalig.',
    do_pay: 'Jaarlijkse donatie met incasso',
    do_pay_once: 'Doneer eenmalig',
    reload: 'Controleer betaling',
    already_paid: 'Al betaald?',
  },
  donor_account: {
    payment_failed:
      'We konden geen betaallink voor u maken. Probeer het later opnieuw.',
    paid_notice: 'We hebben uw donatie succesvol verwerkt.',
    set_donation: 'Donatie instellen',
    saved: 'Voorkeuren opgeslagen',
    max_one_product:
      'U kunt maximaal 1 product kiezen.<br>In de webwinkel kunt u bijbestellen.',
    product_explanation:
      'Kies het product dat u wilt ontvangen bij uw donatie.',
    total_amount: 'Totaalbedrag: { amount }',
    donation_details:
      'U koos voor <strong>{ product }</strong>, met een donatie van <strong>{ amount }</strong>.',
  },
  generic: {
    amount: 'Aantal',
    back: 'Terug',
    confirm: 'Zeker weten?',
    fail_message: 'Er ging iets mis.',
    log_in: 'Inloggen',
    minlength: 'Minimaal { length } tekens',
    or: 'of',
    remove: 'Verwijder',
    save: 'Opslaan',
    total: 'Totaal',
  },
  home: {
    sign_up: 'Aanmelden',
    account: 'Account',
    new_order: 'Nieuwe bestelling',
  },
  login_form: {
    insert_digits: 'Controle van uw e-mailadres',
    button: 'Inloggen',
    confirm_digits: 'Bevestig controlecode',
    invalid_digits: 'Onjuiste controlecode',
    digits: 'Controlecode',
    success:
      'Vul hieronder de 6-cijferige controlecode in die wij u hebben gemaild.',
    terms:
      'Met het versturen van uw e-mailadres gaat u akkoord met ons <a href="https://marrekrite.frl/privacybeleid" class="underline" target="_blank">privacybeleid</a>.',
  },
  new_person: {
    button: 'Aanmelden',
  },
  new_store: {
    success: 'Verkooppunt aangemeld',
    title: 'Verkooppunt aanmelden',
    button: 'Aanmelden',
  },
  order_details: {
    product: 'Product',
  },
  order_form: {
    button: 'Bestellen',
    personal_order: 'Persoonlijke bestelling',
    store_order: 'Zakelijke bestelling',
    no_lines: 'Er zijn nog geen producten toegevoegd.',
    add_line: 'Product toevoegen',
    total: 'Totaalbedrag: {amount}',
  },
  people: {
    email: 'E-mailadres',
    first_name: 'Voornaam',
    last_name: 'Achternaam',
    phone_number: 'Telefoonnummer',
    min_donation_amount: 'De donatie moet minimaal {amount} bedragen',
    donation_amount: 'Donatiebedrag',
    donation_product: 'Product',
    kinds: {
      donor: 'donateur',
      store: 'verkooppunt',
    },
  },
  shop: {
    address: 'Bezorgadres',
    back_to_account: 'Terug naar uw account',
    button: 'Bestelling plaatsen',
    cart: 'Winkelwagen',
    check_again: 'Controleer opnieuw',
    check_input:
      'Wij konden het formulier helaas niet verwerken, controleer de invoer.',
    complete_payments:
      'Voor u een nieuwe bestelling kunt plaatsen moet u eerst de nog bestaande bestellingen voldoen.',
    edit_cart: 'Winkelwagen aanpassen',
    enter_personal_details:
      'Voer uw persoonlijke informatie in om de bestelling af te ronden. In de volgende stap kunt u de bestelling controleren.',
    expect_shipping: 'Er kunnen nog verzendkosten in rekening worden gebracht.',
    log_in_first:
      'Om een bestelling te kunnen plaatsen hebben wij uw e-mailadres nodig. Vul die hieronder in, en klik op de link die wij u sturen.',
    next: 'Volgende',
    not_paid: 'We hebben de betaling nog niet geregistreerd ({ state }).',
    optional_donation: 'Vrijwillige bijdrage',
    paid: 'Uw bestelling is succesvol betaald.',
    product_not_available: 'Niet meer beschikbaar',
    shipping_costs: 'Verzendkosten',
    shopping_for_store: 'U doet een bestelling voor <strong>{ name }</strong>',
    success: 'We hebben uw bestelling in goede orde ontvangen.',
    success_redirect:
      'We hebben uw bestelling in goede orde ontvangen, u wordt doorgestuurd.',
    thanks: 'Bedankt voor uw bestelling',
    title: 'Webwinkel',
    total_amount: 'Totaalbedrag: { amount }',
  },
  sign_up: {
    title: 'Aanmelden als',
    welcome:
      'Welkom bij Recreatieschap Marrekrite, fijn dat u donateur wilt worden. Daarvoor doorloopt u de volgende stappen.',
    steps: {
      1: 'Vul uw e-mailadres in.',
      2: 'Klik op de link die wij u mailen.',
      3: 'Vul uw persoonsgegevens in.',
      4: 'Kies de hoogte van uw donatie en het gewenste product.',
    },
    store_steps: {
      1: 'Vul uw e-mailadres in.',
      2: 'Klik op de link die wij u mailen.',
      3: 'Vul uw persoonsgegevens in.',
      4: 'Kies "Nieuw verkooppunt aanmelden".',
    },
    login: 'Volgende',
  },
  store_orders: {
    empty: 'Nog geen bestellingen geplaatst.',
    title: 'Bestellingen',
    reload: 'Bijwerken',
  },
  store_shop: {
    steps: {
      1: 'Vul uw e-mailadres in.',
      2: 'Klik op de link die wij u mailen.',
      3: 'Bij uw eerste bestelling: Vul uw persoonsgegevens in.',
      4: 'Bij uw eerste bestelling: Voeg een nieuw verkooppunt toe.',
      5: "Kies 'Nieuwe bestelling' bij uw verkooppunt.",
    },
  },
  stores: {
    empty: 'Nog geen verkooppunten aangemeld.',
    name: 'Naam van verkooppunt',
    remove: 'Verkooppunt verwijderen',
    terms_approved: 'Akkoord met de voorwaarden',
  },
}
